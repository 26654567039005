<template>
	<a-drawer :title="formTitle" width="60%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
		<div class="table-operations">
			<a-button type="primary" @click="handleAddLine()">添加行</a-button>
			<!-- <a-button type="primary" :disabled="multiple" @click="handleToChange(selectedRowKeys)">批量删除</a-button> -->
			<a-divider type="vertical" />

		</div>
		<a-table :columns="columns" :data-source="data"
			:row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
			<span slot="drawingTime" slot-scope="text, record">
				<a-date-picker @change="selectDateDraft($event,record)" v-model="record.drawingTime" placeholder="请选择" />
				<!-- <a-input v-model="record.dateDraft" placeholder="请输入"></a-input> -->
			</span>
			<span slot="drawingMoney" slot-scope="text, record">
				<a-input v-model="record.drawingMoney" type="number" placeholder="请输入"></a-input>
			</span>
			<span slot="drawingImg" slot-scope="text, record">
				<a-upload list-type="picture-card" :file-list="record.drawingImgs"
					:customRequest="(file)=>customOssRequest(file,record)">
					<div v-if="record.drawingImgs.length == 0">
						<a-icon type="plus" />
						<div class="ant-upload-text">上传影像</div>
					</div>
				</a-upload>
				<!-- <a-input v-model="record.drawingImg" placeholder="请输入"></a-input> -->
			</span>
			<span slot="repaymentTime" slot-scope="text, record">
				<a-date-picker @change="selectDateDrafts($event,record)" v-model="record.repaymentTime" placeholder="请选择" />
				<!-- <a-input v-model="record.repaymentTime" placeholder="请输入"></a-input> -->
			</span>
			<span slot="repaymentMoney" slot-scope="text, record">
				<a-input v-model="record.repaymentMoney" type="number" placeholder="请输入"></a-input>
			</span>
			<span slot="repaymentImg" slot-scope="text, record">
				<a-upload list-type="picture-card" :file-list="record.repaymentImgs"
					:customRequest="(file)=>customOssRequests(file,record)">
					<div v-if="record.repaymentImgs.length == 0">
						<a-icon type="plus" />
						<div class="ant-upload-text">上传影像</div>
					</div>
				</a-upload>
				<!-- <a-input v-model="record.repaymentImg" placeholder="请输入"></a-input> -->
			</span>
			<span slot="openAccount" slot-scope="text, record">
				<a-select v-model="record.openAccount" style="width: 100px" placeholder="请选择">
					<a-select-option :value="0">否</a-select-option>
					<a-select-option :value="1">是</a-select-option>
				</a-select>
			</span>
			<span slot="operation" slot-scope="text, record,index">
				<a @click="handleDelete(index)">删除</a>
			</span>
		</a-table>
		<div class="bottom-control">
			<a-space>
				<a-button type="primary" :loading="submitLoading" @click="submitForm">保存</a-button>
				<a-button type="dashed" @click="onClose">取消</a-button>
			</a-space>
		</div>
	</a-drawer>
</template>

<script>
	import {
		batchEdit,
		list,
		signature
	} from '@/api/drawing/customer'
	import axios from 'axios'
	export default {
		name: 'WithdrawalDetails',
		data() {
			return {
				formTitle: '提款明细',
				open: false,
				record: {},
				submitLoading: false,
				selectedRowKeys: [],
				selectedRows: [],
				multiple: true,
				data: [{
					drawingTime: '',
					drawingMoney: null,
					drawingImg: '',
					drawingImgs: [],
					repaymentTime: '',
					repaymentMoney: null,
					repaymentImg: '',
					repaymentImgs: [],
					openAccount: undefined
				}],
				columns: [{
						title: '提款日期',
						width: 180,
						dataIndex: 'drawingTime',
						align: 'center',
						scopedSlots: {
							customRender: 'drawingTime'
						},
					},
					{
						title: '提款金额(万)',
						dataIndex: 'drawingMoney',
						align: 'center',
						scopedSlots: {
							customRender: 'drawingMoney'
						},
					},
					{
						title: '提款影像',
						dataIndex: 'drawingImg',
						align: 'center',
						scopedSlots: {
							customRender: 'drawingImg'
						},
					},
					{
						title: '是否开户',
						dataIndex: 'openAccount',
						align: 'center',
						scopedSlots: {
							customRender: 'openAccount'
						},
					},
					{
						title: '还款日期',
						width: 180,
						dataIndex: 'repaymentTime',
						align: 'center',
						scopedSlots: {
							customRender: 'repaymentTime'
						},
					},
					{
						title: '还款金额',
						dataIndex: 'repaymentMoney',
						align: 'center',
						scopedSlots: {
							customRender: 'repaymentMoney'
						},
					},
					{
						title: '还款影像',
						dataIndex: 'repaymentImg',
						align: 'center',
						scopedSlots: {
							customRender: 'repaymentImg'
						},
					},
					{
						title: '操作项',
						align: 'center',
						width: 100,
						scopedSlots: {
							customRender: 'operation'
						},
					}
				],
				ossUploadUrl: 'http://mypkgz.natappfree.cc',
				uploadData: {
					policy: '',
					key: '',
					OSSAccessKeyId: '',
					success_action_status: 200,
					signature: '',
				},
			}
		},
		methods: {
			// 保存
			submitForm() {
				console.log(this.data)
				this.data.map(item => {
					if (item.drawingImgs.length > 0) {
						item.drawingImg = JSON.stringify(item.drawingImgs)
					}
					if (item.repaymentImgs.length > 0) {
						item.repaymentImg = JSON.stringify(item.repaymentImgs)
					}
				})
				let data = {
					addParamList: this.data,
					auditAmountId: this.record.id,
				}
				batchEdit(data).then(res => {
					if (res.success) {
						console.log('提款记录', res);
						this.$message.success(res.message);
						this.data = [{
							drawingTime: '',
							drawingMoney: null,
							drawingImg: '',
							drawingImgs: [],
							repaymentTime: '',
							repaymentMoney: null,
							repaymentImg: '',
							repaymentImgs: [],
							openAccount: undefined
						}]
						this.selectedRowKeys = [];
						this.selectedRows = [];
						this.onClose()
						this.$emit('ok')
					}
				})
			},
			// 选择提款日期
			selectDateDraft(e, record) {
				console.log('选择时间', e, record);
				record.drawingTime = e.format('YYYY-MM-DD');
			},
			selectDateDrafts(e, record) {
				console.log('选择时间', e, record);
				record.repaymentTime = e.format('YYYY-MM-DD');
			},
			// 添加行
			handleAddLine() {
				this.data.push({
					drawingTime: '',
					drawingMoney: null,
					drawingImg: '',
					drawingImgs: [],
					repaymentTime: '',
					repaymentMoney: null,
					repaymentImg: '',
					repaymentImgs: [],
					openAccount: undefined
				})
			},
			// 打开弹窗
			openModel(record) {
				console.log(record)
				this.open = true;
				this.record = record;
				this.getSignature();
				list({
					auditAmountId: record.id
				}).then(res => {
					if (res.success) {
						console.log('提款记录', res);
						res.data.map(item => {
							if (item.drawingImg) {
								item.drawingImgs = JSON.parse(item.drawingImg)
							} else {
								item.drawingImgs = [];
							}
							if (item.repaymentImg) {
								item.repaymentImgs = JSON.parse(item.repaymentImg) || []
							} else {
								item.repaymentImgs = []
							}
						})
						this.data.push(...res.data)
					}
				})
			},
			// 删除行
			handleDelete(index) {
				console.log(index)
				this.data.splice(index, 1);
			},
			// 取消
			onClose() {
				this.open = false;
				this.data = [{
					drawingTime: '',
					drawingMoney: null,
					drawingImg: '',
					drawingImgs: [],
					repaymentTime: '',
					repaymentMoney: null,
					repaymentImg: '',
					repaymentImgs: [],
					openAccount: undefined
				}];
			},
			onSelectChange(selectedRowKeys, selectedRows) {
				this.selectedRowKeys = selectedRowKeys
				this.selectedRows = selectedRows
				// this.ids = this.selectedRows.map(item => item.id)
				// this.single = selectedRowKeys.length !== 1
				this.multiple = !selectedRowKeys.length
			},
			// oss签名
			getSignature() {
				signature().then(res => {
					console.log('oss', res);
					let data = res.data;
					// this.uploadData.key = data
					this.ossUploadUrl = data.resultUrl;
					this.uploadData.policy = data.policy;
					this.uploadData.OSSAccessKeyId = data.accessid;
					this.uploadData.signature = data.signature;
				})
			},
			customOssRequest(info, record) {
				console.log(info, record)
				let formData = new FormData();
				formData.append('policy', this.uploadData.policy);
				formData.append('OSSAccessKeyId', this.uploadData.OSSAccessKeyId);
				formData.append('signature', this.uploadData.signature);
				formData.append('key', info.file.name);
				formData.append('success_action_status', 200);
				formData.append('file', info.file);
				axios.post(this.ossUploadUrl, formData).then(res => {
					console.log(res)
					if (res.status == 200) {
						record.drawingImgs.push({
							name: info.file.name,
							url: this.ossUploadUrl + '/' + info.file.name,
							status: 'done',
							uid: record.drawingImg.length
						})
						console.log(record)
					}
				})
			},
			customOssRequests(info, record) {
				let formData = new FormData();
				formData.append('policy', this.uploadData.policy);
				formData.append('OSSAccessKeyId', this.uploadData.OSSAccessKeyId);
				formData.append('signature', this.uploadData.signature);
				formData.append('key', info.file.name);
				formData.append('success_action_status', 200);
				formData.append('file', info.file);
				axios.post(this.ossUploadUrl, formData).then(res => {
					console.log(res)
					if (res.status == 200) {
						record.repaymentImgs.push({
							name: info.file.name,
							url: this.ossUploadUrl + '/' + info.file.name,
							status: 'done',
							uid: record.drawingImg.length
						})
						console.log(record)
					}
				})
			},
			beforeUpload(info) {
				// console.log(info)
				this.uploadData.key = info.name;
			},
			removeImg(info) {
				// console.log('删除文件', info);
				this.fileList.map((item, index) => {
					if (info.name == item.name) {
						this.fileList.splice(index, 1);
					}
				})
			},
			handleChange(info) {
				console.log(info)
				if (info.file.status !== 'uploading') {
					this.fileList.push({
						name: info.file.name,
						url: this.ossUploadUrl + '/' + info.file.name
					})
				}
			},
			handleToChange(arr) {
				console.log(arr);

			},
			handlePreview() {

			}
		}
	}
</script>

<style>
</style>